
.Lookup {
    width: 50vw;
}

.ListSelector {
    margin-top: 0.2em;
    border: 2px solid var(--peachy-pink);
    background-color: white;
    border-radius: 0.5em;
    outline: none;
    height: 7em;
    min-width: 25em;
}

.ListSelector>li {
    display: block;
    padding: 0.2em 1em;
}

.ListSelector>li[data-is-selected=true] {
    background-color: var(--peachy-pink);
    color: white
}


.options li > * {
}
