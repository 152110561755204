.ElementEditor {
    display: flex;
    flex-direction: column;
    padding: 1em 1.5em 1em 0;
    border-top: 2px dashed var(--pencil-color);
    gap: 1em;
    margin-bottom: 1em;
}


.ElementEditor .addEntry {
    background-color: var(--peachy-purple);
    border-radius: 1em;
    font-size: 0.8em;
    height: 2em;
    width: 2em;
    position: relative;
    margin-left: 2.5em;
    align-self: flex-start;
}

.ElementEditor .deleteEntry i {
    display: inline-block;
}

.ElementEditor .deleteEntry {
    left:-2em;
    background-color: white;
    color: grey;
    border-radius: 1em;
    border: 1px solid var(--pencil-color);
    font-size: 0.8em;
    height: 2em;
    width: 2em;
    align-self: center;
    pointer-events: none;
    opacity: 0;
}

.ElementEditor .deletedEntry {
    opacity: 0.3;
}

.ElementEditor .deletedEntry dt {
    text-decoration: line-through;
}


.tagModal {
    background-color: white;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
    padding: 1em;
    border: 2px solid var(--peachy-pink);
}

.tagModal button {
    align-self: flex-end;
}



.ElementEditor ul {
    line-height: 2em;
    position: relative;
    z-index: 0;
}

.ElementEditor ul>li {
    transition: top 200ms, left 200ms;
    display: flex;
    gap: 0.5em;
    align-items: center;
    position: relative;
}

.ElementEditor ul>li:hover .deleteEntry {
    pointer-events: all;
    opacity: 1;
}



:global(.spacePressed) .ElementEditor ul>li[data-grabbed=true] {
    transition: none;
    z-index: 1;
}

.ElementEditor dl {
    user-select: none;
    display: flex;
    column-gap: 1em;
}

.ElementEditor dt {
    color: var(--peachy-purple);
    font-weight: normal;
}
.ElementEditor dd>span {
    display: flex;
}

.ElementEditor dd>span>i {
    width: 2em;
}


.ElementEditor dd>span>pre {
    font-family: Poppins, sans-serif;
    font-style: italic;
    font-size: 0.8em;
    background-color: transparent;
    opacity: 0.3;
}
