.MainNav {
    background-color: var(--pencil-color);
    height: 100%;
}

.MainNav {
    display: flex;
    flex-direction: column;
    padding: 2em 1em;
}

.MainNav li a {
    border: 1px solid transparent;
    font-size: 0.8em;
    display: block;
    color: var(--text-color);
    padding: 0.5em;
    border-radius: 0.5em;
}

.MainNav .active {
    border: 1px solid var(--pencil-color);
    background-color: white;
}