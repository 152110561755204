.details {
    min-width: 800px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, auto));
    grid-column-gap: 1em;
    grid-row-gap: 1em;
}

.wideField {
    grid-column: span 2;
}

.container {
    display: flex;
    flex-direction: column;
    row-gap: 2em;
}

.container>button {
    padding: 0.5em 1em;
    align-self: center;
}

.table :where(th, td) {
    padding: 0.5rem;
    position: relative;
    vertical-align: middle;
    font-size: medium;
}

.table a {
    color: unset;
}