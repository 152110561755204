.stat {
    display: inline-grid;
    width: 100%;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.stat-figure {
    grid-column-start: 2;
    grid-row: span 3 / span 3;
    grid-row-start: 1;
    place-self: center;
    justify-self: end;
}
.stat-title {
    grid-column-start: 1;
    white-space: nowrap;
}
.stat-value {
    grid-column-start: 1;
    white-space: nowrap;
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 800;
}
.stat-desc {
    grid-column-start: 1;
    white-space: nowrap;
    font-size: 0.8rem;
    line-height: 1rem;
}

.accent {
    color: var(--peachy-purple)
}
.primary {
    color: var(--peachy-pink)
}
.info {
    color: var(--peachy-green)
}
