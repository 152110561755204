.svg {
    background-position: center;
    background-repeat: no-repeat;
}

.consultationsAndDiagnostics {
    background-image: url(../../../public/assets/benefits/ConsultationsAndDiagnostics.svg);
}
.dental {
    background-image: url(../../../public/assets/benefits/Dental.svg);
}
.dentalAndOptical {
    background-image: url(../../../public/assets/benefits/DentalAndOptical.svg);
}
.hospitalCare {
    background-image: url(../../../public/assets/benefits/HospitalCare.svg);
}
.mentalHealthInPatient {
    background-image: url(../../../public/assets/benefits/MentalHealthInPatient.svg);
}
.mentalHealthOutPatient {
    background-image: url(../../../public/assets/benefits/MentalHealthOutPatient.svg);
}
.optical {
    background-image: url(../../../public/assets/benefits/Optical.svg);
}
.therapies {
    background-image: url(../../../public/assets/benefits/Therapies.svg);
}
.virtualGP {
    background-image: url(../../../public/assets/benefits/VirtualGP.svg);
}
