.AdminResetPassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5em;
}


.AdminResetPassword fieldset {
    display: inline-flex;
    flex-direction: column;
    width: 15em;
    gap: 1em;
}

.AdminResetPassword fieldset button {
    width: 100%;
}


.toggle span {
    font-size: 0.8em;
    padding-left: 0.5em;
}

.toggle > input {
    height: 1em;
    width: 1.5em;
    background-color: var(--pencil-color) !important;
}


.toggle > input::before {
    background-color: white;
}

.toggle > input:checked {
    background-color: var(--peachy-green) !important;
}

