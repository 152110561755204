
.Header {
    height:4em;
    background-color: white;
    display:flex;
    gap: 2em;
    align-items: center;
    padding: 0 1em;
    border-bottom: 1px solid var(--pencil-color)
}

.Logo {
    width: 8em
}


.signOut {
    margin-left: auto;
}


.Header ul {
    display: flex;
    gap: 1em;
}
