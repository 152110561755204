.claimsCostTable table {
    width: 100%
}

.claimsCostTable table td:nth-child(3) {
    width: 100%;
}

.QnA {
    overflow-y: scroll;
    max-height: 90vh;
}
