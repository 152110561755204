.Spinner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
    margin-bottom: 10vh;
}

.Spinner div {
    height: 2em;
    width: 2em;
    background-color: var(--peachy-pink);
    border-radius: 1em;
    animation: pulse 400ms ease-out infinite alternate;
    transform: scale(0);
    opacity: 0;
}

.Spinner div:nth-child(2) {
    animation-delay: 125ms;
}
.Spinner div:nth-child(3) {
    animation-delay: 250ms;
}

@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
