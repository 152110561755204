@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&display=swap');

@font-face {
    font-family: 'Naoko';
    src: url('./naoko-06-extrabold-webfont.woff') format('woff');
}

:root {
    --paper-color: #F5F7Fa;
    --pencil-color: #dddddd;

    --peachy-pink: #FF5B65;
    --peachy-purple: #6D0E6F;
    --peachy-green: #33EFA9;

    --peachy-pink-light: #FFD7E5;
    --peachy-pink-pale: #FF9A9F;
    --peachy-purple-light: #D3B7D4;
    --peachy-green-light: #C9F9E7;

    --neutral-color: #f5f7fa;
    --neutral-dark: #B0B8C7;

    --body-font: 'Poppins', sans-serif;
    --display-font: 'Naoko', 'Montserrat', sans-serif;

    --text-color: black;

    --card-shadow: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.1)) drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.1));
    --button-shadow: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.1)) drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.1));

    --error: var(--peachy-pink);
    --warning: orange;
}

html {
    scroll-behavior: smooth;
}

html, body, #app-root {
    min-height: 100vh;
}


body {
    font-family: var(--body-font);
    font-size: 14pt;
    background-color: var(--paper-color);
}

p {
    line-height: 1.5em;
}

h1 {
    font-weight: 900;
    font-size:3em;
    font-family: var(--display-font);
    text-transform: uppercase;
}

h2 {
    font-weight: 900;
    font-size: 2em;
    font-family: var(--display-font);
    text-transform: uppercase;
}

h3 {
    font-weight: 900;
    font-size: 1.5em;
    font-family: var(--display-font);
    text-transform: uppercase;
}

h4 {
    font-weight: 700 ;
    font-size: 1.5em;
    font-family: var(--body-font);
}

h5 {
    font-weight: 700 ;
    font-size: 1.2em;
    font-family: var(--body-font);
}

h6 {
    font-weight: 400 ;
    font-size: 1em;
    font-family: var(--body-font);
}


input[type=text], input[type=password], [type=number], [type=date], select {
    display: block;
    width: 100%;
    background-color: white;
    border: 1px solid var(--pencil-color);
    padding: 0.5em;
    border-radius: 0.5em;
}

a {
    color: var(--peachy-pink);
    cursor: pointer;
}

button {
    transition: all 200ms;
    background-color: var(--peachy-pink);
    color: white;
    border-radius: 0.5em;
    display: inline-flex;
    justify-content: center;
    padding: 0.5em;
}

.fa-button {
    cursor: pointer;
    background-color: var(--peachy-pink);
    color: var(--neutral-color);
}

.fa-button.fa-solid {
    background-color: inherit;
    color: var(--peachy-pink);
}

button:focus .fa-button:focus {
    filter: var(--button-shadow);
}

button:disabled .fa-button:disabled {
    filter: opacity(0.5);
}

mark {
    background-color: white;
    color: var(--peachy-pink);
    font-size: 0.8em;
    font-weight: 600;
}

mark::before {
    content: '!';
    width: 1em;
    display: inline-block;
    background-color: var(--peachy-pink);
    color: white;
    text-align: center;
    margin-right: 0.5em;
    border-radius: 100%;
}
