


.RepoView header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.RepoView li[data-copy-status]>dl>dd>span>pre {
    background-color: turquoise!important;
    transition: none;
}
.RepoView li[data-copy-status="copy-json"]>ul dl {
    background-color: paleturquoise!important;
    transition: none;
}

.RepoView dl {
    user-select: none;
}

.RepoView dt {
    color: rebeccapurple;
}
.RepoView dd>span {
    display: flex;
}

.RepoView dd>span>i {
    width: 2em;
}

.RepoView dd>span>pre {
    font-family: Poppins, sans-serif;
    font-style: italic;
    font-size: 0.8em;
    background-color: transparent;
    transition: background-color 500ms;
    opacity: 0.3;
}

.RepoView dl {
    background-color: transparent;
}

:global(.shiftPressed) .RepoView dl {
    transition: background-color 500ms;
}


