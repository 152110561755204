.RepoNodeEditor {
    background-color: white;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
    padding: 1em;
    border: 2px solid var(--peachy-pink);
    min-height: 10em;
    min-width: 26em;
}


.PropEditor {
    height: 2em;
    display: flex;
    align-items: center;
    column-gap: 0.5em;
}

.PropEditor>*:last-child {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}



.PropEditor .tag {
    max-width: 10em;
    align-self: center;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--peachy-purple)
}


.PropEditor .type {
    font-style: italic;
    cursor: pointer;
}


.RepoNodeEditor button {
    align-self: flex-end;
}

.RepoNodeEditor .validButton {

}


.RepoNodeEditor .invalidButton {
    opacity: 0.5;
    pointer-events: none;
}

.ValueEditor .toggle {
    font-size: 2em;
}




.dataTypeList {
    border-radius: 1em;
    position: relative;
    top: 1.1em
}

.dataTypeListItem {
    padding: 0.2em 0.5em;
    cursor: pointer;
}

.dataTypeListItem:hover {
    background-color: mistyrose;
}

.dataTypeSelectedListItem {
    background-color: var(--peachy-pink)!important;
    color: white;
}



.ValueEditor>input[type='number'] {
}


.DataTypeSelector {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.DataTypeSelector label {
    display: flex;
    cursor: pointer;
    column-gap: 0.5em;
    align-self: stretch;
}

.DataTypeSelector label>input {
    position: relative;
    appearance: none;
    outline: none;
}

.DataTypeSelector label>input:after {
    display: inline-block;
    content: ' ';
    border-radius: 50%;
    border: 1px solid var(--peachy-pink);
    background-color: transparent;
    transition: background-color 200ms;
    height:0.7em;
    width: 0.7em;
}


.DataTypeSelector label>input:checked:after {
    background-color: var(--peachy-pink);
}
