.RepoStatus {
    display: flex;
    column-gap: 0.5em;
    align-items: center;
}

.RepoStatus .status {
    border: 1px solid var(--peachy-purple);
    background-color: var(--peachy-purple-light);
    border-radius: 1em;
    display: inline-block;
    padding: 0 0.6em;
    color: var(--peachy-purple);
    opacity: 1;
    transition: opacity 200ms;
}


.RepoStatus .inSync {
    opacity: 0;
}

.RepoStatus .notInSync {
    opacity: 1;
}