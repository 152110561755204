.Dashboard {
    display: grid;
    height: 100vh;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'HEADER HEADER'
        'NAV MAIN';
}

.Dashboard>header {
    grid-area: HEADER;
}

.Dashboard>nav {
    display: block;
    grid-area: NAV;
}

.Dashboard>main {
    grid-area: MAIN;
}
