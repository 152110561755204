.container  {
    display: flex;
    justify-content: space-evenly;
    width: 10em;
}

.container > button  {
    width: 70px;
}

.container > button:disabled  {
    opacity: 0.3;
}

