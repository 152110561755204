.newRowHighlight {
    background-color: var(--peachy-green-light);
}

.clickable:hover {
  cursor: pointer;
}

.readOnlyRow {
  background-color: var(--peachy-purple-light);
}

.table {
  position: relative;
  text-align: left;
}
.table th:first-child {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  z-index: 11;
}
.table :where(th, td) {
  padding: 0.75rem;
  vertical-align: middle;
  font-size: medium;
}
.table :where(thead, tfoot) :where(th, td) {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}
.table:where(:not(.table-zebra)) :where(thead, tbody, tfoot) :where(tr:not(:last-child) :where(th, td)) {
  border-bottom-width: 1px;
  border-color: var(--pencil-color)
}

.table tr {
  transition: 1s linear all;
}

.numeric.table {
  text-align: right;
}

.table .textual {
  text-align: left;
}