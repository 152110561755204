.modal {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border: 1px solid var(--neutral-dark);
    border-radius: 0.5em;
    margin: auto;
    max-height: 70vh;
    position: relative;
    overflow-y: scroll;
    row-gap: 1em;
    max-width: 80%;
    min-width: 50em;
}

.modal section {
    padding: 2em 2em 1em 2em;
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    overflow-y: scroll;
    align-items: start;
    width: 100%;
}

.modal ul {
    margin-inline-start: 0.5em;
    list-style: disc inside;
}
